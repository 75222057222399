<script setup lang="ts">
import { type Component, computed, ref } from "vue";
import { useRouter } from "vue-router";

import { GROUP_BY_PROPERTY_KINDS_EXCLUDED_FROM_LIST, UNGROUPED_PSEUDO_GROUP_BY } from "~/common/groupBy";
import { getPropertyConfig, getPropertyWithConfigList, getShownPropertyWithConfigList } from "~/common/properties";
import Animated from "~/components/dumb/Animated.vue";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import DefaultPropertyEditor from "~/components/topbar/PropertyDefaultEditor.vue";
import {
  COLOR_BY_LAYOUT_KINDS,
  GROUP_BY_LAYOUT_KINDS,
  LAYOUT_KIND_TO_NAME_AND_ICON,
  SUBTASK_DISPLAY_MODES_TO_NAME_AND_ICON,
} from "~/constants/layout";
import { ChevronDownIcon, HideIcon, InfoIcon, PlusIcon, PropertiesIcon, ShowIcon } from "~/icons";
import { makeLinkToPropertySettingsRef } from "~/router/common";
import {
  ButtonSize,
  ButtonStyle,
  CommandId,
  DropdownMenuItemKind,
  LayoutKind,
  PageKind,
  Placement,
  PropertyKind,
  SubtaskDisplayMode,
} from "~/shared/enums";
import type { Position, Property, PropertyConfig, PropertyValue } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { validateAlwaysTrue } from "~/utils/common";
import { makePropertyComparator, positionComparator } from "~/utils/comparator";

const LAYOUT_KIND_TO_COMMAND_ID_MAP = new Map<LayoutKind, CommandId>([
  [LayoutKind.LIST, CommandId.SET_LAYOUT_TO_LIST],
  [LayoutKind.BOARD, CommandId.SET_LAYOUT_TO_BOARD],
  [LayoutKind.CALENDAR, CommandId.SET_LAYOUT_TO_CALENDAR],
  [LayoutKind.ROADMAP, CommandId.SET_LAYOUT_TO_ROADMAP],
]);

const NOT_SHOWN_PROPERTY_KINDS = new Set([
  PropertyKind.DEFAULT_DARTBOARD,
  PropertyKind.DEFAULT_DESCRIPTION,
  PropertyKind.DEFAULT_ATTACHMENTS,
]);

const props = defineProps<{
  layoutKind: LayoutKind;
  subtaskDisplayMode: SubtaskDisplayMode;
  showAbsentees: boolean;
  groupBy: string;
  hideEmptyGroups: boolean;
  colorBy: string;
}>();

const emit = defineEmits<{
  setLayoutKind: [layoutKind: LayoutKind];
  setSubtaskDisplayMode: [subtaskDisplayMode: SubtaskDisplayMode];
  setShowAbsentees: [showAbsentees: boolean];
  setHideEmptyGroups: [hideEmptyGroups: boolean];
  setPropertyDuidVisibility: [propertyDuid: string, shown: boolean, propertyOrderDuids?: string[]];
  setGroupBy: [groupBy: string];
  setColorBy: [colorBy: string];
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();

const setLayoutKind = (layoutKind: LayoutKind) => emit("setLayoutKind", layoutKind);

const kindConfigs = computed(() =>
  [...LAYOUT_KIND_TO_NAME_AND_ICON.entries()].map(([kind, [title, icon]]) => ({ kind, title, icon }))
);

// Shown properties
const cachedShownPropertyDuids = ref<Set<string>>(new Set());
const shownPropertiesWithConfig = computed(() => {
  // TODO this has the wrong order for properties that are not shown (and therefore shown in the dropdown)
  // they should be in 'standard' order per the properties settings page, but they are ordered by appStore.propertyOrderDuids, which could have a cached value for them
  const fullComparator = makePropertyComparator(appStore.propertyOrderDuids);
  const comparator = (aDuid: string, aPosition: Position | undefined, bDuid: string, bPosition: Position | undefined) =>
    positionComparator(aPosition, bPosition) || fullComparator(aDuid, bDuid);
  const alwaysIncludeKinds = [PropertyKind.DEFAULT_TITLE];
  if (appStore.currentPage?.pageKind === PageKind.VIEW) {
    alwaysIncludeKinds.push(PropertyKind.DEFAULT_DARTBOARD);
  }
  return getShownPropertyWithConfigList(alwaysIncludeKinds, [...cachedShownPropertyDuids.value])
    .map(([property, config]): { property: Property; config: PropertyConfig; hidden: boolean; locked: boolean } => {
      const locked =
        config.alwaysShowForLayouts?.includes(appStore.layoutKind) ??
        alwaysIncludeKinds.includes(property.kind) ??
        false;
      return {
        property,
        config,
        hidden: !locked && !appStore.isPropertyShownInLayout(property, config),
        locked,
      };
    })
    .sort((a, b) => comparator(a.property.duid, a.config.lockPosition, b.property.duid, b.config.lockPosition));
});
const shownPropertyDuids = computed(
  () => new Set(shownPropertiesWithConfig.value.map(({ property }) => property.duid))
);
cachedShownPropertyDuids.value = new Set([...shownPropertyDuids.value]);

const propertyOptions = computed(() =>
  getPropertyWithConfigList()
    .filter(
      ([property]) => !shownPropertyDuids.value.has(property.duid) && !NOT_SHOWN_PROPERTY_KINDS.has(property.kind)
    )
    .map(([property, config]) => ({
      value: property.duid,
      label: property.title,
      selected: false,
      component: DropdownMenuItemContent,
      componentArgs: {
        title: property.title,
        icon: config.icon,
        isMultiSelect: true,
      },
    }))
);

const addShownProperty = (propertyDuid: string) => {
  const propertyOrderDuids =
    appStore.propertyOrderDuids.length === 0 ? [...shownPropertyDuids.value] : [...appStore.propertyOrderDuids];

  if (!propertyOrderDuids.includes(propertyDuid)) {
    // Place before last locked property
    const lastLockedPropertyIndex = shownPropertiesWithConfig.value.findLastIndex(({ locked }) => locked);
    if (lastLockedPropertyIndex === -1) {
      propertyOrderDuids.push(propertyDuid);
    } else {
      propertyOrderDuids.splice(lastLockedPropertyIndex, 0, propertyDuid);
    }
  }

  emit("setPropertyDuidVisibility", propertyDuid, true, propertyOrderDuids);
};
const toggleShownProperty = (propertyDuid: string, newShown: boolean) => {
  emit("setPropertyDuidVisibility", propertyDuid, newShown, undefined);
};
const onCreateProperty = () => {
  router.replace(makeLinkToPropertySettingsRef(undefined).value);
};

// Subtask
const subtaskModeEnabled = computed(() => props.layoutKind !== LayoutKind.CALENDAR);
const subtaskNameAndIcon = computed(() => SUBTASK_DISPLAY_MODES_TO_NAME_AND_ICON.get(props.subtaskDisplayMode));
const subtaskDropdownSections = computed(() => [
  {
    title: "Modify",
    items: [...SUBTASK_DISPLAY_MODES_TO_NAME_AND_ICON.entries()].map(([displayMode, [title, icon]]) => ({
      title,
      icon,
      kind: DropdownMenuItemKind.BUTTON,
      disabled: displayMode === props.subtaskDisplayMode,
      onClick: () => emit("setSubtaskDisplayMode", displayMode),
    })),
  },
]);

// Group by
const groupByEnabled = computed(() => GROUP_BY_LAYOUT_KINDS.has(props.layoutKind));
const groupByOptions = computed(() =>
  appStore.groupByDefinitionList.filter(
    (e) => appStore.layoutKind !== LayoutKind.LIST || !GROUP_BY_PROPERTY_KINDS_EXCLUDED_FROM_LIST.has(e.property.kind)
  )
);
const groupByNameAndIcon = computed<[string, Component] | null>(() => {
  const option = groupByOptions.value.find((e) => e.property.duid === props.groupBy);
  if (!option) {
    return null;
  }
  return [option.property.title, option.icon];
});
const groupBySections = computed(() => [
  {
    title: "Group by",
    items: groupByOptions.value.map((option) => ({
      title: option.property.title,
      icon: option.icon,
      kind: DropdownMenuItemKind.BUTTON,
      disabled: option.property.duid === props.groupBy,
      onClick: () => emit("setGroupBy", option.property.duid),
    })),
  },
]);

// Color by
const colorByEnabled = computed(() => COLOR_BY_LAYOUT_KINDS.has(props.layoutKind));
const colorByOptions = computed(() => appStore.groupByDefinitionList);
const colorByNameAndIcon = computed<[string, Component] | null>(() => {
  const option = colorByOptions.value.find((e) => e.property.duid === props.colorBy);
  if (!option) {
    return null;
  }
  return [option.property.title, option.icon];
});
const colorBySections = computed(() => [
  {
    title: "Color by",
    items: colorByOptions.value.map((option) => ({
      title: option.property.title,
      icon: option.icon,
      kind: DropdownMenuItemKind.BUTTON,
      disabled: option.property.duid === props.colorBy,
      onClick: () => emit("setColorBy", option.property.duid),
    })),
  },
]);

const currentDartboard = computed(() =>
  appStore.currentDartboardOrDefault ? dataStore.getDartboardByDuid(appStore.currentDartboardOrDefault.duid) : undefined
);

const defaultPropertyDuidSet = computed(
  () => new Set(currentDartboard.value ? Object.keys(currentDartboard.value.defaultPropertyMap) : [])
);

const addDefaultProperty = (propertyDuid: string) => {
  if (!currentDartboard.value) {
    return;
  }
  dataStore.updateDartboard({
    duid: currentDartboard.value.duid,
    defaultPropertyMap: {
      ...currentDartboard.value.defaultPropertyMap,
      [propertyDuid]: null,
    },
  });
};

const sortedPropertyList = computed(() =>
  getPropertyWithConfigList()
    .filter(
      ([property, config]) =>
        !config.readOnly && !NOT_SHOWN_PROPERTY_KINDS.has(property.kind) && config.propertyDefault !== null
    )
    .map(([property]) => property)
    .sort((a, b) => makePropertyComparator([])(a.duid, b.duid))
);

const sortedDefaultProperties = computed(() =>
  sortedPropertyList.value
    .filter((property) => defaultPropertyDuidSet.value.has(property.duid))
    .map((property) => ({
      duid: property.duid,
      value: currentDartboard.value?.defaultPropertyMap[property.duid],
    }))
    .filter((e): e is { duid: string; value: PropertyValue } => e.value !== undefined)
);

const propertyDropdownSections = computed(() => [
  {
    title: "Properties",
    items: sortedPropertyList.value
      .filter((property) => !defaultPropertyDuidSet.value.has(property.duid))
      .map((property) => {
        const propertyConfig = getPropertyConfig(property.kind);
        return {
          title: property.title,
          kind: DropdownMenuItemKind.BUTTON,
          icon: propertyConfig.icon,
          onClick: () => addDefaultProperty(property.duid),
        };
      }),
  },
]);
</script>

<template>
  <div
    class="flex w-full flex-col gap-2 pt-2"
    data-testid="layout-editor-inner"
    :class="subtaskModeEnabled || groupByEnabled || colorByEnabled ? 'pb-1' : 'pb-2'">
    <div class="relative mx-3 flex h-[72px] items-center gap-1 rounded border text-md border-hvy">
      <Tooltip
        v-for="kindConfig in kindConfigs"
        :key="kindConfig.kind"
        :command-id="LAYOUT_KIND_TO_COMMAND_ID_MAP.get(kindConfig.kind)"
        class="relative h-full w-1/3">
        <div
          class="z-[1] flex size-full cursor-pointer flex-col items-center justify-center gap-1 rounded"
          :class="kindConfig.kind !== layoutKind ? 'hover:bg-md' : 'bg-md hover:bg-hvy'"
          data-testid="layout-kind"
          @click="setLayoutKind(kindConfig.kind)"
          @keydown.enter="setLayoutKind(kindConfig.kind)">
          <component :is="kindConfig.icon" class="pt-0.5 icon-lg" />
          <span class="select-none text-sm">{{ kindConfig.title }}</span>
        </div>
      </Tooltip>
    </div>
    <div class="flex flex-col gap-1 px-2">
      <!-- Subtask -->
      <div
        v-if="subtaskModeEnabled && subtaskNameAndIcon"
        class="flex w-full select-none items-center justify-between rounded px-2 py-1">
        <div>Subtasks</div>
        <DropdownMenu
          :sections="subtaskDropdownSections"
          :placement="Placement.BOTTOM_RIGHT"
          :width-pixels="192"
          :distance="2"
          prevent-close-on-select>
          <div class="flex w-48 items-center justify-between rounded border px-1 py-0.5 border-lt hover:bg-lt">
            <div class="flex items-center gap-2">
              <component :is="subtaskNameAndIcon[1]" class="text-vlt icon-sm" />
              <div>{{ subtaskNameAndIcon[0] }}</div>
            </div>

            <ChevronDownIcon class="text-vlt icon-sm" />
          </div>
        </DropdownMenu>
      </div>

      <!-- Show absentees -->
      <div
        v-if="subtaskDisplayMode !== SubtaskDisplayMode.FLAT"
        class="flex w-full items-center justify-between gap-4 rounded px-2 py-1">
        <div class="select-none whitespace-nowrap">Show absent parents</div>
        <Toggle
          :value="showAbsentees"
          label="Show absentees"
          hide-label
          :size="ButtonSize.SMALL"
          @update="(showAbsentees) => emit('setShowAbsentees', showAbsentees)" />
      </div>

      <!-- Group by -->
      <div
        v-if="groupByEnabled && groupByNameAndIcon"
        class="flex w-full select-none items-center justify-between gap-4 rounded px-2 py-1">
        <div class="whitespace-nowrap">Group by</div>
        <DropdownMenu
          :sections="groupBySections"
          :placement="Placement.BOTTOM_RIGHT"
          :width-pixels="190"
          :distance="2"
          prevent-close-on-select>
          <div
            class="flex w-48 items-center justify-between overflow-hidden rounded border px-1 py-0.5 border-lt hover:bg-lt">
            <div class="flex items-center gap-2">
              <component :is="groupByNameAndIcon[1]" class="text-vlt icon-sm" />
              <div class="truncate" :title="groupByNameAndIcon[0]">{{ groupByNameAndIcon[0] }}</div>
            </div>

            <ChevronDownIcon class="text-vlt icon-sm" />
          </div>
        </DropdownMenu>
      </div>

      <!-- Hide empty groups -->
      <div
        v-if="groupByEnabled && groupBy !== UNGROUPED_PSEUDO_GROUP_BY"
        class="flex w-full items-center justify-between gap-4 rounded px-2 py-1">
        <div class="select-none whitespace-nowrap">Hide empty groups</div>
        <Toggle
          :value="hideEmptyGroups"
          label="Hide empty groups"
          hide-label
          :size="ButtonSize.SMALL"
          @update="(hideEmptyGroups) => emit('setHideEmptyGroups', hideEmptyGroups)" />
      </div>

      <!-- Color by -->
      <DropdownMenu
        v-if="colorByEnabled && colorByNameAndIcon"
        :sections="colorBySections"
        :placement="Placement.BOTTOM_RIGHT"
        :width-pixels="180"
        :distance="2"
        prevent-close-on-select>
        <div class="flex w-full select-none items-center justify-between gap-4 rounded px-2 py-1 hover:bg-lt">
          <div class="whitespace-nowrap">Color by</div>
          <div class="flex items-center gap-2 overflow-hidden">
            <component :is="colorByNameAndIcon[1]" class="text-vlt icon-sm" />
            <div class="truncate" :title="colorByNameAndIcon[0]">{{ colorByNameAndIcon[0] }}</div>
          </div>
        </div>
      </DropdownMenu>

      <hr class="-ml-5 w-[400px] border-lt" />

      <!-- Properties -->
      <div class="flex w-full flex-col gap-1 rounded px-2 py-1">
        <span class="select-none text-base">Properties</span>
        <Animated class="flex flex-wrap items-center gap-1 pt-1">
          <Tooltip
            v-for="{ property, config, hidden, locked } in shownPropertiesWithConfig"
            :key="property.duid"
            :text="
              locked
                ? `${property.title} is always shown in this layout`
                : `${hidden ? 'Show' : 'Hide'} ${property.title.toLowerCase()}`
            ">
            <button
              :aria-label="`${hidden ? 'Show' : 'Hide'} ${property.title.toLowerCase()}`"
              type="button"
              class="group/property-chip relative flex max-w-52 select-none items-center gap-1 rounded-full border px-2 py-0.5"
              :class="[
                locked && 'cursor-not-allowed opacity-50',
                !hidden && !locked && 'bg-lt',
                hidden ? 'border-lt' : 'border-md',
              ]"
              :disabled="locked"
              @click="() => toggleShownProperty(property.duid, hidden)">
              <component :is="config.icon" class="text-vlt icon-sm" />
              <span class="truncate">{{ property.title }}</span>
              <div
                v-if="!locked"
                class="pointer-events-none absolute inset-0 flex items-center justify-center rounded-full opacity-0 transition-all group-hover/property-chip:opacity-100"
                :class="hidden ? 'bg-std' : 'bg-lt'">
                <component :is="hidden ? ShowIcon : HideIcon" class="text-vlt icon-sm" />
              </div>
            </button>
          </Tooltip>

          <!-- Add property -->
          <MultiselectDropdownMenu
            placeholder="Add a property"
            :items="propertyOptions"
            :placement="Placement.BOTTOM_RIGHT"
            :new-entry-icon="PropertiesIcon"
            :new-entry-icon-args="{ class: 'icon-sm text-vlt' }"
            :make-new-entry-text="() => 'Create custom property'"
            :distance="2"
            :validate="validateAlwaysTrue"
            @add="addShownProperty"
            @create="onCreateProperty">
            <Tooltip text="Show more properties in this layout">
              <Button
                :btn-style="ButtonStyle.SECONDARY"
                :size="ButtonSize.CHIP"
                class="rounded-full !p-0.5"
                :icon="PlusIcon"
                borderless
                is-contrast
                a11y-label="Property" />
            </Tooltip>
          </MultiselectDropdownMenu>
        </Animated>
      </div>

      <hr v-if="appStore.currentPage?.pageKind === PageKind.DARTBOARD" class="-ml-5 w-[400px] border-lt" />
      <!-- Property defaults -->
      <div
        v-if="currentDartboard && appStore.currentPage?.pageKind === PageKind.DARTBOARD"
        class="flex w-full flex-col px-2 pt-1">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <span class="select-none text-base">Property defaults</span>

            <Tooltip
              info
              :skidding="-10"
              text="The default value(s) that tasks will use when they are created in this dartboard"
              class="cursor-help">
              <InfoIcon class="cursor-help rounded-full outline-none bg-std text-vlt icon-xs" />
            </Tooltip>
          </div>

          <DropdownMenu :sections="propertyDropdownSections" :distance="2" :placement="Placement.RIGHT_TOP">
            <span class="cursor-pointer rounded p-0.5 text-lt hover:bg-md">
              <PlusIcon class="icon-md" />
            </span>
          </DropdownMenu>
        </div>

        <div class="flex flex-col gap-1 pt-2">
          <DefaultPropertyEditor
            v-for="{ duid, value } in sortedDefaultProperties"
            :key="duid"
            :dartboard="currentDartboard"
            :property-duid="duid"
            :value="value" />
        </div>
      </div>
    </div>
  </div>
</template>
