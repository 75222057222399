<script setup lang="ts">
import { useElementSize } from "@vueuse/core";
import { type Component, computed, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import FormError from "~/components/dumb/FormError.vue";
import { HideIcon, ShowIcon } from "~/icons";
import { ButtonStyle } from "~/shared/enums";
import type { ValidationFunction } from "~/shared/types";
import { makeUuid } from "~/utils/common";

const props = defineProps<{
  initValue?: string;
  inputType?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  icon?: Component;
  label?: string;
  largeLabel?: boolean;
  hideLabel?: boolean;
  hideLabelNonempty?: boolean;
  borderless?: boolean;
  isForm?: boolean;
  hideError?: boolean;
  inputClasses?: string;
  showErrorAlways?: boolean;
  dataTestid?: string;
  scaleWithHeight?: boolean;
  autocomplete?: string;
  validate?: ValidationFunction;
  transformValue?: (value: string) => string;
}>();

const emit = defineEmits<{
  blur: [];
  change: [newValue: string];
  click: [event: MouseEvent];
  enter: [event: KeyboardEvent];
  finalize: [newValue: string];
  keydown: [event: KeyboardEvent];
}>();

const inputRef = ref<HTMLInputElement | null>(null);

const value = ref(props.initValue ?? "");

watch(
  () => props.initValue,
  (newValue) => {
    if (newValue === undefined || newValue === value.value) {
      return;
    }
    value.value = newValue;
  }
);

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const newValue = target.value;

  value.value = props.transformValue ? props.transformValue(newValue) : newValue;
  emit("change", value.value);
};

const id = ref(`input-${makeUuid()}`);

const isPassword = computed(() => props.inputType === "password");
const showPassword = ref(false);
const typeNormalized = computed(() => (isPassword.value && showPassword.value ? "text" : (props.inputType ?? "text")));

const placeholderNorm = computed(() => props.placeholder ?? props.label);
const isTouched = ref(props.showErrorAlways);
const hasFocus = ref(false);

const validationResult = computed(() => props.validate?.(value.value));
const isValid = computed(() => {
  if (props.required && value.value === "") {
    return false;
  }
  return validationResult.value?.isValid ?? true;
});
const errorMsg = computed(() => {
  if (validationResult.value && !validationResult.value.isValid) {
    return validationResult.value.error;
  }

  if (props.required && value.value === "") {
    return "Enter a value";
  }

  return "";
});

const onFocus = () => {
  isTouched.value = props.showErrorAlways ?? false;
  hasFocus.value = true;
};

const onFocusout = () => {
  isTouched.value = true;
  hasFocus.value = false;
  emit("finalize", value.value);
};

const focus = () => {
  setTimeout(() => {
    inputRef.value?.focus();
  });
};

const select = () => {
  setTimeout(() => {
    inputRef.value?.select();
  });
};

const blur = () => {
  setTimeout(() => {
    inputRef.value?.blur();
  });
};

const clear = () => {
  value.value = "";
};

// Scale font size with height
const { height: inputHeight } = useElementSize(inputRef);
const dynamicFontSize = computed(() => `${inputHeight.value * 0.7}px !important`);

defineExpose({
  focus,
  blur,
  clear,
  hasFocus,
  isTouched,
  isValid,
  select,
  value,
});
</script>

<template>
  <div class="relative flex flex-col">
    <label
      :for="id"
      :class="{
        'absolute -top-2 left-1.5 select-none px-1 text-xs transition-opacity bg-std text-lt': !largeLabel,
        'select-none text-sm font-medium leading-7 text-md': largeLabel,
        'sr-only': hideLabel || (hideLabelNonempty && !value),
      }">
      {{ `${label}${required ? " *" : ""}` }}
    </label>
    <input
      :id="id"
      ref="inputRef"
      v-model="value"
      :type="typeNormalized"
      :required="required"
      :disabled="disabled"
      :placeholder="placeholderNorm"
      :autocomplete="autocomplete"
      class="w-full rounded border bg-transparent px-3 py-2 text-md focus-ring-none placeholder:text-vlt focus:border-primary-base dark:focus:border-primary-base"
      :class="[
        {
          'border-danger-base': isTouched && !isValid,
          'hover:border-danger-hover-light dark:hover:border-danger-hover-dark': isTouched && !isValid && !disabled,
          'border-md': (!isTouched || isValid) && !borderless,
          'border-transparent': (!isTouched || isValid) && borderless,
          'hover:border-hvy': (!isTouched || isValid) && !disabled,
          'pl-10': icon,
          'min-h-8 !px-2 !py-[5px]': isForm,
          'pr-[38px]': isPassword,
          'text-sm': !scaleWithHeight,
        },
        inputClasses,
      ]"
      :style="scaleWithHeight ? { fontSize: dynamicFontSize } : {}"
      :data-testid="dataTestid"
      @click="emit('click', $event)"
      @keydown="emit('keydown', $event)"
      @focus="onFocus"
      @focusout="onFocusout"
      @keydown.enter="emit('enter', $event)"
      @keydown.enter.esc.prevent="inputRef?.blur()"
      @input="onInput"
      @blur="emit('blur')" />
    <component
      :is="icon"
      v-if="icon"
      class="pointer-events-none -mt-7 ml-3 flex shrink-0 items-center text-lt icon-md" />
    <div v-if="isPassword" class="absolute right-0 top-0 z-10 m-1.5">
      <Button
        :btn-style="ButtonStyle.CHIP"
        :icon="showPassword ? HideIcon : ShowIcon"
        borderless
        class="!p-0.5 text-vlt"
        a11y-label="Show password toggle"
        @click="showPassword = !showPassword" />
    </div>
    <FormError v-if="!hideError && validate" :msg="errorMsg" :show="isTouched && errorMsg.length > 0" />
  </div>
</template>
