<script setup lang="ts">
import "vue-simple-calendar/dist/style.css";

import { useMediaQuery } from "@vueuse/core";
import moment, { type Moment } from "moment";
import { computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref } from "vue";
import { CalendarView } from "vue-simple-calendar";

import actions from "~/actions";
import { getPropertyConfig } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import PageEmptyState from "~/components/dumb/PageEmptyState.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { colorsByTheme } from "~/constants/style";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, PlusIcon } from "~/icons";
import { ButtonStyle, DartboardKind, DropdownMenuItemKind, EditorMode, PageKind, Placement } from "~/shared/enums";
import type { CalendarDisplayPeriod, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { fromHexToHexWithAlpha } from "~/utils/color";
import { isString, isTimeTracking } from "~/utils/common";
import { normalizeDate } from "~/utils/date";

import { TASK_CONTEXT_MENU_OPTIONS } from "../constants";

type PeriodDefinition = {
  period: CalendarDisplayPeriod;
  title: string;
  count: number;
  format: string | ((date: Moment) => string);
};
type ICalendarItem = InstanceType<typeof CalendarView>["items"][number];
type INormalizedCalendarItem = Parameters<NonNullable<InstanceType<typeof CalendarView>["onClick-item"]>>[0];

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const formatWeekRange = (date: Moment) => {
  moment.updateLocale("en", {
    week: {
      dow: userStore.firstDayOfWeek,
    },
  });
  const startOfWeek = moment(date).startOf("week");
  const endOfWeek = moment(startOfWeek).add(6, "day");

  if (startOfWeek.isSame(endOfWeek, "month")) {
    return `${startOfWeek.format("MMMM Do")} - ${endOfWeek.format("Do, YYYY")}`;
  }
  if (startOfWeek.isSame(endOfWeek, "year")) {
    return `${startOfWeek.format("MMMM Do")} - ${endOfWeek.format("MMMM Do, YYYY")}`;
  }
  return `${startOfWeek.format("MMMM Do, YYYY")} - ${endOfWeek.format("MMMM Do, YYYY")}`;
};

const PERIOD_DEFINITIONS: PeriodDefinition[] = [
  {
    period: "week",
    title: "Week",
    count: 1,
    format: formatWeekRange,
  },
  {
    period: "month",
    title: "Month",
    count: 1,
    format: "MMMM YYYY",
  },
  {
    period: "year",
    title: "Year",
    count: 1,
    format: "YYYY",
  },
];

const showDate = ref<Date>(new Date());
const displayPeriod = computed(() => appStore.calendarDisplayPeriod);
const displayPeriodCount = computed(() => appStore.calendarDisplayPeriodCount);
const periodDefinition = computed<PeriodDefinition | undefined>(() =>
  PERIOD_DEFINITIONS.find((e) => e.period === displayPeriod.value && e.count === displayPeriodCount.value)
);
const periodLabel = computed(() => {
  const date = moment(showDate.value);
  const format = periodDefinition.value?.format;
  if (!format || isString(format)) {
    return date.format(format);
  }
  return format(date);
});

const canAddTasks = computed(
  () => appStore.currentPage?.pageKind !== PageKind.VIEW && appStore.currentPage?.kind !== DartboardKind.FINISHED
);

const dow0RightBorder = computed(() => (userStore.firstDayOfWeek === 1 ? 0 : "1px"));
const dow5RightBorder = computed(() => (userStore.firstDayOfWeek === 6 ? 0 : "1px"));
const dow6RightBorder = computed(() => (userStore.firstDayOfWeek === 0 ? 0 : "1px"));

const dropdownSections = computed(() => [
  {
    title: "Zoom",
    items: Array.from(PERIOD_DEFINITIONS.values()).map((definition) => ({
      title: definition.title,
      kind: DropdownMenuItemKind.BUTTON,
      disabled: displayPeriod.value === definition.period && displayPeriodCount.value === definition.count,
      onClick: () => {
        appStore.setCalendarDisplayPeriod(definition.period);
        appStore.setCalendarDisplayPeriodCount(definition.count);
      },
    })),
  },
]);

const showPrevPeriod = () => {
  showDate.value = moment(showDate.value).subtract(displayPeriodCount.value, displayPeriod.value).toDate();
};

const showToday = () => {
  if (displayPeriod.value === "week") {
    showDate.value = moment().startOf("isoWeek").toDate();
    return;
  }
  if (displayPeriod.value === "month") {
    showDate.value = moment().startOf("isoWeek").add(6, "day").startOf("month").toDate();
    return;
  }
  showDate.value = moment().startOf("year").toDate();
};

const showNextPeriod = () => {
  showDate.value = moment(showDate.value).add(displayPeriodCount.value, displayPeriod.value).toDate();
};

showToday();

const colors = computed(() => colorsByTheme[pageStore.theme]);
const colorByDefinition = computed(() => appStore.colorByDefinition);
const propertyConfig = computed(() => getPropertyConfig(colorByDefinition.value.property.kind));
const modeAlphaMod = computed(() => (pageStore.theme === "light" ? 0 : -0.05));
// TODO combine with duplicated code in RM
const getTaskColor = (task: Task) => {
  const defaultColor = colors.value.bgHvy;
  if (!propertyConfig.value) {
    return defaultColor;
  }

  const { groups, property } = colorByDefinition.value;
  let value = propertyConfig.value.getValue(property, task);

  if (Array.isArray(value) && !isTimeTracking(value)) {
    if (value.length !== 1) {
      return defaultColor;
    }
    value = value[0];
  }

  const group = groups.find((e) => e.value === value);
  return group?.colorHex ?? defaultColor;
};

const dueTasks = computed<ICalendarItem[]>(() =>
  appStore.filteredAndSortedTasksInPage
    .filter((e) => e.startAt || e.dueAt)
    .map((task, i) => {
      const taskColor = getTaskColor(task);
      const selected = appStore.selectedTaskDuids.has(task.duid);
      const baseColor = selected
        ? fromHexToHexWithAlpha(taskColor, 0.3 + modeAlphaMod.value)
        : fromHexToHexWithAlpha(taskColor, 0.2 + modeAlphaMod.value);
      const hoverColor = selected
        ? fromHexToHexWithAlpha(taskColor, 0.35 + modeAlphaMod.value)
        : fromHexToHexWithAlpha(taskColor, 0.25 + modeAlphaMod.value);

      return {
        id: task.duid,
        startDate: moment(task.startAt ?? task.dueAt)
          .startOf("day")
          .add(i, "millisecond") // hack to force vue-simple-calendar to order properly
          .toDate(),
        endDate: moment(task.dueAt ?? task.startAt)
          .startOf("day")
          .toDate(),
        title: task.title,
        style: `--baseColor: ${baseColor}; --hoverColor: ${hoverColor};`,
        classes: [`duid-${task.duid}`],
      };
    })
);

const onItemClick = (item: INormalizedCalendarItem) => {
  const task = appStore.filteredAndSortedTasksInPage.find((t) => t.duid === item.id);
  if (!task) {
    return;
  }

  actions.visualization.selectRowByIdAndScroll(task.duid);
  appStore.setTaskDetailOpen(true);
};

const onItemDrop = (item: INormalizedCalendarItem, date: Date) => {
  const task = appStore.filteredAndSortedTasksInPage.find((t) => t.duid === item.id);
  if (!task) {
    return;
  }

  if (!tenantStore.startDateEnabled) {
    dataStore.updateTasks([
      {
        duid: task.duid,
        dueAt: date.toISOString(),
        startAt: moment(date).set("hour", 9).set("minute", 0).toISOString(),
      },
    ]);
    return;
  }

  dataStore.updateTasks([
    {
      duid: task.duid,
      startAt: date.toISOString(),
      dueAt: moment(date).add(moment(item.endDate).diff(item.startDate, "day"), "day").toISOString(),
    },
  ]);
};

const createTask = (day: Date) => {
  appStore.setTcmOpen(true);
  nextTick(() => {
    if (!dataStore.taskDraft) {
      return;
    }
    const date = normalizeDate(moment(day));
    const updates = [
      { duid: dataStore.taskDraft.duid, startAt: tenantStore.startDateEnabled ? date : undefined, dueAt: date },
    ];
    dataStore.updateTasks(updates, { noUndo: true, noBackend: true });
    // TODO lots of awkwardness here because of race condition around setting backend field
    setTimeout(() => {
      dataStore.updateTasks(updates, { noUndo: true });
    }, 1000);
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const startEditingTask = (taskDuid: string) => {
  // TODO implement
  throw new Error("startEditingTask not implemented for calendar");
};

const onContextMenu = (event: MouseEvent) => {
  if (pageStore.isPublicView || (tenantStore.isDart && !pageStore.adminHidden && event.altKey)) {
    return;
  }
  const elem = event.target as HTMLElement | null;
  if (!elem) {
    return;
  }
  const taskDuid = [...elem.classList].find((c) => c.startsWith("duid-"))?.split("-")[1];
  if (!taskDuid) {
    return;
  }
  const task = dataStore.getTaskByDuid(taskDuid);
  if (!task) {
    return;
  }
  appStore.openContextMenu(
    event as PointerEvent,
    actions.context.task(task, EditorMode.CALENDAR),
    TASK_CONTEXT_MENU_OPTIONS
  );
};

const isPrinting = useMediaQuery("print");

if (!pageStore.isPublicView) {
  pageStore.pageLoaded = true;
}

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.calendar = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.calendar = null;
});

defineExpose({
  createTask,
  startEditingTask,
});
</script>

<template>
  <div
    class="absolute inset-0 z-auto flex h-full flex-col overflow-auto p-4 bg-std"
    data-testid="calendar"
    @contextmenu="onContextMenu">
    <CalendarView
      :items="dueTasks"
      :enable-drag-drop="!pageStore.isPublicView"
      :enable-html-titles="false"
      :show-date="showDate"
      :starting-day-of-week="((userStore.firstDayOfWeek + 6) % 7) + 1"
      item-border-height="8px"
      item-top="34px"
      class="theme-default"
      :display-period-uom="displayPeriod"
      :display-period-count="displayPeriodCount"
      :month-name-on1st="false"
      @click-item="onItemClick"
      @drop-on-date="onItemDrop">
      <template #header>
        <div class="flex items-center justify-between py-2 pl-4 pr-2">
          <div class="cursor-default select-none text-md">{{ periodLabel }}</div>
          <div v-if="!isPrinting" class="flex gap-1">
            <!-- Navigation -->
            <Tooltip text="Previous period">
              <Button
                :btn-style="ButtonStyle.SECONDARY"
                :icon="ChevronLeftIcon"
                borderless
                class="!p-0 bg-lt icon-lg hover:bg-md"
                a11y-label="Previous period"
                @click="showPrevPeriod" />
            </Tooltip>
            <Tooltip text="Jump to today">
              <Button
                :btn-style="ButtonStyle.SECONDARY"
                text="Today"
                borderless
                class="h-6 !px-2 !py-0 bg-lt hover:bg-md"
                @click="showToday" />
            </Tooltip>
            <Tooltip text="Next period">
              <Button
                :btn-style="ButtonStyle.SECONDARY"
                :icon="ChevronRightIcon"
                borderless
                class="!p-0 bg-lt icon-lg hover:bg-md"
                a11y-label="Next period"
                @click="showNextPeriod" />
            </Tooltip>
            <!-- Period selector -->
            <DropdownMenu
              :sections="dropdownSections"
              :placement="Placement.BOTTOM_RIGHT"
              :distance="2"
              :width-pixels="120">
              <button
                type="button"
                class="flex h-6 cursor-pointer select-none items-center justify-center gap-1 rounded p-0.5 pl-2 pr-1 text-sm bg-lt text-md focus-ring-std enabled:hover:bg-md disabled:cursor-not-allowed disabled:opacity-50 dark:enabled:hover:bg-zinc-700">
                {{ periodDefinition?.title ?? "Unknown range" }}
                <ChevronDownIcon class="text-lt icon-xs" />
              </button>
            </DropdownMenu>
          </div>
        </div>
      </template>
      <template #day-content="{ day }">
        <div v-if="canAddTasks" class="group/day absolute inset-0">
          <div class="absolute inset-x-0 bottom-0 z-10 hidden py-0.5 pr-1 bg-std group-hover/day:flex">
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :icon="PlusIcon"
              borderless
              class="grow py-0.5 print:hidden"
              a11y-label="Create a task"
              @click="createTask(day)" />
          </div>
        </div>
      </template>
    </CalendarView>
    <PageEmptyState
      v-if="dueTasks.length === 0"
      class="bg-gradient-to-b from-transparent to-50% to-std"
      :is-filter-mode="appStore.allTasksInPage.length > 0" />
  </div>
</template>

<style>
.cv-header,
.cv-header-days,
.cv-weeks,
.cv-item {
  border: none;
}

.cv-header-day,
.cv-week,
.cv-day,
.cv-header button {
  border-color: v-bind("colors.borderLt");
}

.cv-header-day,
.cv-day-number,
.cv-item,
.cv-header {
  color: v-bind("colors.textMd");
}

.cv-header-day {
  @apply cursor-default select-none justify-center border-t-0 !text-xs;
  color: v-bind("colors.textLt");
}

/* hide right borders depending on presence of next day */
.cv-header-day.dow0,
.cv-day.dow0 {
  border-right-width: v-bind("dow0RightBorder");
}
.cv-header-day.dow5,
.cv-day.cv-header-day.dow5 {
  border-right-width: v-bind("dow5RightBorder");
}
.cv-header-day.dow6,
.cv-day.dow6 {
  border-right-width: v-bind("dow6RightBorder");
}

/* increase height of cells for year view */
.cv-week {
  @apply !min-h-24;
}

.cv-day {
  @apply justify-center;
}
.cv-day-number {
  @apply mt-0.5 rounded-full border-2 border-transparent p-1 text-center !text-sm/4;
}
.past > .cv-day-number {
  color: v-bind("colors.textVlt");
}
.today .cv-day-number {
  @apply w-fit min-w-7 border-primary-base text-center;
}

.cv-item {
  @apply ml-px h-6 cursor-pointer truncate border-none bg-[--baseColor] px-1.5 py-0.5 !text-sm hover:bg-[--hoverColor] dark:bg-[--baseColor] dark:hover:bg-[--hoverColor];
}
.cv-item:not(.continued) {
  @apply rounded-l;
}
.cv-item:not(.toBeContinued) {
  @apply rounded-r;
}
.cv-item:not(.toBeContinued).span1 {
  width: calc((100% / 7) - 6px);
}
.cv-item:not(.toBeContinued).span2 {
  width: calc((200% / 7) - 6px);
}
.cv-item:not(.toBeContinued).span3 {
  width: calc((300% / 7) - 6px);
}
.cv-item:not(.toBeContinued).span4 {
  width: calc((400% / 7) - 6px);
}
.cv-item:not(.toBeContinued).span5 {
  width: calc((500% / 7) - 6px);
}
.cv-item:not(.toBeContinued).span6 {
  width: calc((600% / 7) - 6px);
}
.cv-item:not(.toBeContinued).span7 {
  width: calc((700% / 7) - 6px);
}

.cv-day-number:before {
  @apply mr-1.5;
}
.d01-01 .cv-day-number:before {
  content: "Jan";
}
.d02-01 .cv-day-number:before {
  content: "Feb";
}
.d03-01 .cv-day-number:before {
  content: "Mar";
}
.d04-01 .cv-day-number:before {
  content: "Apr";
}
.d05-01 .cv-day-number:before {
  content: "May";
}
.d06-01 .cv-day-number:before {
  content: "Jun";
}
.d07-01 .cv-day-number:before {
  content: "Jul";
}
.d08-01 .cv-day-number:before {
  content: "Aug";
}
.d09-01 .cv-day-number:before {
  content: "Sep";
}
.d10-01 .cv-day-number:before {
  content: "Oct";
}
.d11-01 .cv-day-number:before {
  content: "Nov";
}
.d12-01 .cv-day-number:before {
  content: "Dec";
}
</style>
