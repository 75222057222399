<script setup lang="ts">
import { type Component, computed } from "vue";

import Button from "~/components/dumb/Button.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ButtonSize, ButtonStyle, CommandId, IconSize } from "~/shared/enums";
import { usePageStore } from "~/stores";

defineProps<{
  mainIcon: Component;
  title: string;
  description?: string;
  buttonIcon?: Component;
  buttonText?: string;
  buttonCommand?: CommandId;
}>();

const emit = defineEmits<{
  click: [];
}>();

const pageStore = usePageStore();

const iconBg = computed(() =>
  pageStore.theme === "light"
    ? "linear-gradient(180deg, #ffffff 0%, #d1d5db 100%), #ffffff"
    : "linear-gradient(180deg, #1e1e21 0%, #52525b 100%), #1e1e21"
);
</script>

<template>
  <div class="pointer-events-none absolute inset-0 flex size-full flex-col items-center justify-evenly">
    <div class="flex w-64 flex-col items-center gap-6">
      <div
        class="dart-icon-grad-bg flex shrink-0 items-center justify-center rounded-lg border p-3 shadow-lg border-md dark:shadow-zinc-50/10">
        <component :is="mainIcon" class="size-10 text-lt" />
      </div>
      <div class="flex w-full flex-col items-center justify-center gap-1">
        <p class="select-none text-center font-semibold text-md">{{ title }}</p>
        <p v-if="description" class="w-full select-none hyphens-auto text-center text-sm text-lt break-words">
          {{ description }}
        </p>
      </div>
      <div class="flex items-center gap-4">
        <slot name="extraButton" />
        <Tooltip v-if="buttonText" :disabled="!buttonCommand" :command-id="buttonCommand">
          <Button
            :btn-style="ButtonStyle.PRIMARY"
            :size="ButtonSize.SMALL"
            :icon-size="IconSize.S"
            :text="buttonText"
            data-testid="empty-state-button"
            :icon="buttonIcon"
            class="pointer-events-auto"
            :class="buttonIcon && 'pl-2'"
            @click="emit('click')" />
        </Tooltip>
      </div>
    </div>
    <div />
  </div>
</template>

<style scoped>
.dart-icon-grad-bg {
  background: v-bind(iconBg);
}
</style>
