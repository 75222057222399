<script setup lang="ts">
import type { EditorState, SerializedEditorState } from "lexical";
import { computed, ref } from "vue";

import { EditorMode } from "~/shared/enums";
import { usePageStore } from "~/stores";
import { areLexicalStatesSame, isLexicalStateEmpty, makeEmptyLexicalState } from "~/utils/common";

import { EntityNode } from "./nodes/EntityNode";
import { SmartMatchNode } from "./nodes/SmartMatchNode";
import DisableFormattingPlugin from "./plugins/DisableFormattingPlugin.vue";
import EnterPlugin from "./plugins/EnterPlugin.vue";
import OnChangePlugin from "./plugins/OnChangePlugin.vue";
import PasteMultilineTextEventPlugin from "./plugins/PasteMultilineTextEventPlugin.vue";
import SelectionPlugin from "./plugins/SelectionPlugin.vue";
import SingleLinePlugin from "./plugins/SingleLinePlugin.vue";
import SmartMatchPlugin from "./plugins/smartMatch";
import TabPlugin from "./plugins/TabPlugin.vue";
import { EntityTypeaheadPlugin } from "./plugins/typeahead";
import TextEditor from "./TextEditor.vue";

const NODES = [EntityNode, SmartMatchNode];

const props = defineProps<{
  initialTitle?: SerializedEditorState;
  editorMode: EditorMode;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  change: [title: SerializedEditorState];
  blur: [];
  tab: [event: KeyboardEvent];
  enter: [event: KeyboardEvent];
  pasteMultilineText: [text: string];
}>();

const pageStore = usePageStore();

const title = ref<SerializedEditorState>(props.initialTitle ?? makeEmptyLexicalState());
const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const isRoadmapMode = computed(() => props.editorMode === EditorMode.ROADMAP);
const isTcmMobileMode = computed(() => props.editorMode === EditorMode.TCM && pageStore.isMobile);
const smallText = computed(() => isListMode.value || isBoardMode.value || isRoadmapMode.value || isTcmMobileMode.value);

const textEditor = ref<InstanceType<typeof TextEditor> | null>(null);
const selectionPlugin = ref<InstanceType<typeof SelectionPlugin> | null>(null);
const smartMatchPlugin = ref<InstanceType<typeof SmartMatchPlugin> | null>(null);

const namespace = computed(() => `title-${EditorMode[props.editorMode].toLowerCase()}`);

const hasFocus = computed(() => !!textEditor.value?.hasFocus);

const handleEditorChanges = (newEditorState: EditorState) => {
  const newTitle = newEditorState.toJSON();
  if (areLexicalStatesSame(title.value, newTitle)) {
    return;
  }

  title.value = newTitle;
  emit("change", title.value);

  // Reset smart match blacklist if the title is empty
  if (isLexicalStateEmpty(title.value)) {
    smartMatchPlugin.value?.resetBlacklist();
  }
};

const focus = () => {
  textEditor.value?.focus();
};

const setEditorState = (newEditorState: SerializedEditorState) => {
  textEditor.value?.setEditorState(newEditorState);
};

const clear = () => setEditorState(makeEmptyLexicalState());

const select = (position: number) => selectionPlugin.value?.select(position);

defineExpose({
  hasFocus,
  clear,
  focus,
  select,
  setEditorState,
});
</script>

<template>
  <TextEditor
    ref="textEditor"
    :namespace="namespace"
    :nodes="NODES"
    :initial-state="initialTitle"
    :placeholder="`Add a task${isBoardMode || isRoadmapMode || isTaskDetailMode || isTcmMobileMode ? '' : ', / to set a property'}`"
    :single-line="isListMode"
    :grow="isListMode || isRoadmapMode"
    :min-height-px="isListMode || isTcmMobileMode ? 32 : isBoardMode || isRoadmapMode ? 20 : 42"
    :top-margin-px="isListMode || isTcmMobileMode ? undefined : isBoardMode || isRoadmapMode ? -1 : 4"
    :left-margin-px="isListMode ? 15 : isBoardMode ? 1 : isRoadmapMode ? 8 : 10"
    :right-margin-px="isBoardMode ? -5 : undefined"
    :unrounded="isListMode"
    extra-rounded
    :disabled="disabled"
    always-borderless
    :large-text="!smallText"
    :small-leading="isBoardMode"
    :no-wrap="isRoadmapMode"
    data-testid="title-text-editor"
    @blur="emit('blur')">
    <template v-if="!pageStore.isPublicView" #default>
      <!-- custom plugins -->
      <EnterPlugin @enter="(event) => emit('enter', event)" />
      <EntityTypeaheadPlugin :has-focus="hasFocus" include-special-mentions exclude-dart-links />
      <EntityTypeaheadPlugin :has-focus="hasFocus" mentions-only include-special-mentions exclude-dart-links />
      <OnChangePlugin :has-focus="hasFocus" @change="handleEditorChanges" />
      <PasteMultilineTextEventPlugin
        v-if="isListMode || isBoardMode"
        @paste-multiline-text="(text) => emit('pasteMultilineText', text)" />
      <SelectionPlugin ref="selectionPlugin" />
      <SmartMatchPlugin ref="smartMatchPlugin" />
      <SingleLinePlugin />
      <DisableFormattingPlugin />
      <TabPlugin v-if="isBoardMode" @tab="(event) => emit('tab', event)" />
    </template>
  </TextEditor>
</template>
