<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import DropdownMenuWithSelection from "~/components/dumb/DropdownMenuWithSelection.vue";
import Input from "~/components/dumb/Input.vue";
import Modal from "~/components/dumb/Modal.vue";
import { DartIcon } from "~/icons";
import { ButtonStyle, ModalWidth } from "~/shared/enums";
import { useAppStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { makeValidateTenantName } from "~/utils/common";

const PAGE_TITLE = "Set up Dart";

const appStore = useAppStore();
const pageStore = usePageStore();
const userStore = useUserStore();
const tenantStore = useTenantStore();

const iconBg = computed(() =>
  pageStore.theme === "light"
    ? "linear-gradient(180deg, #ffffff 0%, #d1d5db 100%), #ffffff"
    : "linear-gradient(180deg, #1e1e21 0%, #32323F 100%), #1e1e21"
);

const nameInput = ref<InstanceType<typeof Input> | null>(null);

const selectedRole = ref<string | null>(null);
const roleOptions = computed(() =>
  [
    "Project or Program Management",
    "Engineering",
    "Software Engineering",
    "Marketing",
    "Operations",
    "Design",
    "Product Management",
    "Information Technology",
    "Finance or Accounting",
    "Administrative",
    "Customer Experience",
    "Sales",
    "Legal",
    "Data or Analytics",
    "Other",
  ].map((kind) => ({
    title: kind,
    selected: kind === selectedRole.value,
    onClick: () => {
      selectedRole.value = kind;
    },
  }))
);

const selectedReferral = ref<string | null>(null);
const referralOptions = computed(() =>
  ["Coworker", "Friend", "News", "Blog", "Email", "Web Search", "Product Hunt", "X", "LinkedIn", "TikTok", "Other"].map(
    (referral) => ({
      title: referral,
      selected: referral === selectedReferral.value,
      onClick: () => {
        selectedReferral.value = referral;
      },
    })
  )
);

const validateTenantName = computed(() => makeValidateTenantName(tenantStore.isDart));

const submitButtonEnabled = computed(() => nameInput.value?.isValid);

const createWorkspace = () => {
  if (!submitButtonEnabled.value || !nameInput.value) {
    return;
  }

  const newName = nameInput.value.value.trim();
  tenantStore.name = newName;
  appStore.setOnboardingModalOpen(false);
  const newPageTitle = `${pageStore.pageTitle.replace(/\|$/, "").trimEnd()} | ${tenantStore.name}`;
  pageStore.pageTitle = newPageTitle;
  document.title = newPageTitle;

  backendOld.workspace.finishOnboarding(newName, {
    role: selectedRole.value,
    referral: selectedReferral.value,
  });
};

// Leave onboarding if tenant has a name, or name got set
watch(
  () => tenantStore.name,
  (tenantName) => {
    if (tenantName !== "") {
      appStore.setOnboardingModalOpen(false);
    }
  },
  { immediate: true }
);

watch(
  () => appStore.onboardingModalOpen,
  (newValue) => {
    if (!newValue) {
      return;
    }

    setTimeout(() => {
      document.title = PAGE_TITLE;
      nameInput.value?.select();
    }, 100);
  },
  { immediate: true }
);
</script>

<template>
  <Modal
    :entity="appStore.onboardingModalOpen"
    title="Finish setup"
    hide-title
    :width="ModalWidth.S"
    custom-styles="sm:h-fit h-full dark:shadow-zinc-50/10"
    close-icon-styles="hidden"
    backdrop-blur
    :style="{ '--icon-bg': iconBg }">
    <template #default>
      <div class="flex flex-col gap-6">
        <div class="mt-2 flex flex-col items-center gap-6">
          <div
            class="dart-icon-grad-bg flex w-fit shrink-0 items-center justify-center rounded-2xl border p-3 shadow-md border-md dark:shadow-zinc-50/10">
            <DartIcon class="size-8 shrink-0" />
          </div>

          <h3
            data-testid="onboarding-finish-setup"
            class="self-center hyphens-auto text-center text-2xl font-semibold text-md break-words">
            Finish setup
          </h3>
          <div class="w-full text-center text-base font-normal text-lt">
            Create your workspace where you and your team can work on projects, tasks, and docs.
          </div>
        </div>

        <div class="-mb-3">
          <Input
            ref="nameInput"
            :init-value="`${userStore.firstName}'s Dart`"
            autocomplete="name"
            label="Workspace name"
            large-label
            required
            placeholder="Enter the name of your team"
            :validate="validateTenantName"
            @enter="createWorkspace" />
        </div>
        <hr class="border-md" />

        <div>
          <span class="text-sm font-medium leading-7 text-md">What kind of work do you do?</span>
          <DropdownMenuWithSelection :options="roleOptions" border :width-pixels="318">
            <div
              :class="[!selectedRole && 'text-lt']"
              class="w-full rounded px-3 py-2 text-left text-sm shadow-sm focus-ring-std hover:bg-lt">
              {{ selectedRole ?? "Select one" }}
            </div>
          </DropdownMenuWithSelection>
        </div>

        <div>
          <span class="text-sm font-medium leading-7 text-md">How did you hear about Dart?</span>
          <DropdownMenuWithSelection :options="referralOptions" border :width-pixels="318">
            <div
              :class="[!selectedReferral && 'text-lt']"
              class="w-full rounded px-3 py-2 text-left text-sm shadow-sm focus-ring-std hover:bg-lt">
              {{ selectedReferral ?? "Select one" }}
            </div>
          </DropdownMenuWithSelection>
        </div>
      </div>
    </template>

    <template #actions>
      <Button
        data-testid="onboarding-get-started"
        :btn-style="ButtonStyle.PRIMARY"
        text="Get started!"
        :disabled="!submitButtonEnabled"
        block
        class="mt-3"
        @click="createWorkspace" />
    </template>
  </Modal>
</template>

<style scoped>
.dart-icon-grad-bg {
  background: var(--icon-bg);
}
</style>
